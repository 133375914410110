var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"blackMediaBadges") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":34,"column":29}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"content-provider-badge content-provider-badge--content-details\">\n                            <img class=\"content-provider-badge__img--content-details content-details-badge-img\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"contentProvider") : stack1), depth0))
    + "\" />\n                        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <!-- share button -->\n                    <div class=\"media-details-button-wrapper\">\n                        <button aria-label=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"share") : stack1), depth0))
    + "\" id=\"bb-media-details-share-button\"\n                                class=\"btn-action brand-primary pointer\" data-toggle=\"tooltip\"\n                                title=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"share") : stack1), depth0)) != null ? stack1 : "")
    + "\">\n                            <svg xmlns=\"http://www.w3.org/2000/svg\" height=\"1.1em\" viewBox=\"0 0 512 512\" fill=\"currentColor\">\n                                <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->\n                                <path d=\"M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-96-96zM64 112c0-8.8 7.2-16 16-16h8c17.7 0 32-14.3 32-32s-14.3-32-32-32H80C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H432c44.2 0 80-35.8 80-80V112c0-44.2-35.8-80-80-80h-8c-17.7 0-32 14.3-32 32s14.3 32 32 32h8c8.8 0 16 7.2 16 16V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112z\"/>\n                            </svg>\n                        </button>\n                    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"content-details-project-link\">\n                <div>\n                    <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"projectLink") : depth0)) != null ? lookupProperty(stack1,"link") : stack1), depth0))
    + "\"\n                        target=\"_blank\"\n                        id=\"project-link\"\n                        class=\"btn-outline-rounded btn-outline-brand-primary\">\n                        <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"submitToProject") : stack1), depth0))
    + "</span>\n                    </a>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content-details-masthead-container bg-details\">\n    <div class=\"container\">\n        <!--cover and Title row-->\n        <div class=\"row-same-height collection-details-masthead\">\n            <div class=\"content-details-thumbnail\">\n                <!-- content image-->\n                <div class=\"cntntmast-thumb content-group-thumb\">\n                    <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailUrl","hash":{},"data":data,"loc":{"start":{"line":8,"column":30},"end":{"line":8,"column":46}}}) : helper)))
    + "\" alt=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"thumbnailAlt") : stack1), depth0))
    + "\">\n                    <meta itemprop=\"thumbnailUrl\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":59},"end":{"line":9,"column":75}}}) : helper)))
    + "\" />\n                </div>\n            </div>\n\n            <div class=\"content-details-headline\">\n                <div class=\"headline-content-type icon-clr-primary p-r-10\">\n                    <i class=\"bbico bbico-collection m-r-5 text-uppercase\"></i>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"collection") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "\n                </div>\n            </div>\n  \n            <div class=\"content-details-sub-headline\">\n                <div>\n                    <span class=\"text-italic\" >"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"anthologyIncluded") : stack1), depth0))
    + "</span>\n                    <a class=\"parent-anthology d-block\" href=\"/"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "/"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n                        "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n                    </a>\n                </div>\n            </div>\n\n            <div class=\"content-details-badges\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBadges") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":35,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n\n\n            <div class=\"content-details-share\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showShareLinks") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":52,"column":23}}})) != null ? stack1 : "")
    + "\n                <!-- curation add button -->\n                <button aria-label=\""
    + ((stack1 = alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addNameToCuration") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0)) != null ? stack1 : "")
    + "\" id=\"bb-media-details-curation-button\"\n                        class=\"btn-action brand-primary pointer hide\" data-toggle=\"tooltip\"\n                        title=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToCuration") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n                    <i class=\"bbico bbico-add\"></i>\n                </button>\n            </div>\n\n            <div class=\"content-details-title\">\n                <div class=\"m-b-5\">\n                    <h1 aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":64,"column":36},"end":{"line":64,"column":44}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabel") || (depth0 != null ? lookupProperty(depth0,"typeLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabel","hash":{},"data":data,"loc":{"start":{"line":64,"column":45},"end":{"line":64,"column":58}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":64,"column":60},"end":{"line":64,"column":68}}}) : helper)))
    + "</h1>\n                </div>\n            </div>\n\n            <div class=\"collection-details-description\">\n                <div id=\"details-description-collapse-wrapper\">\n                    <div id=\"bb-media-details-description-container\" class=\"collapse\">\n                        <div id=\"description-container\" class=\"p-r-10\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":71,"column":71},"end":{"line":71,"column":88}}}) : helper))) != null ? stack1 : "")
    + "</div>\n                    </div>\n                </div>\n                <div id=\"description-collapse-button\">\n                    <button type=\"button\" class=\"btn-text btn-text-brand-primary collapsed\" data-toggle=\"collapse\" data-target=\"#bb-media-details-description-container\">\n                        <span class=\"more\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"more") : stack1), depth0))
    + "</span>\n                        <span class=\"less\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"less") : stack1), depth0))
    + "</span>\n                    </button>\n                </div>\n            </div>\n\n        </div>\n\n        <!-- project link -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"projectLink") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":8},"end":{"line":96,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n<div id=\"bb-media-details-sticky-nav-region\"></div>\n<div id=\"bb-media-details-sub-page-region\" class=\"content-details-sub-pag-region\"></div>\n";
},"useData":true});