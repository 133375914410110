var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-body text-center social-dialog\">\n  <button class=\"close bbico bbico-times fnt-12\" data-dismiss=\"modal\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"close") : stack1), depth0))
    + "\"><i></i></button>\n\n    <h2 class=\"modal-title p-t-30 p-b-10\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"shareIt") : stack1), depth0))
    + "</h2>\n\n    <div class=\"bb-share-social-container\">\n        <div class=\"bb-share-social-buttons-container list-group\">\n            <a target=\"_blank\" href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"facebookUrl") || (depth0 != null ? lookupProperty(depth0,"facebookUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"facebookUrl","hash":{},"data":data,"loc":{"start":{"line":8,"column":37},"end":{"line":8,"column":52}}}) : helper)))
    + "\" id=\"bb-share-facebook\" class=\"list-group-item\">\n                <div class=\"bb-share-social-button-container\">\n                    <div class=\"btn-social facebook bb-share-social-icon\">\n                        <i class=\"fa fa-facebook\"></i>\n                    </div>\n                    <label class=\"pointer\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"post") : stack1), depth0))
    + "</label>\n                </div>\n            </a>\n\n            <a target=\"_blank\" href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"twitterUrl") || (depth0 != null ? lookupProperty(depth0,"twitterUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"twitterUrl","hash":{},"data":data,"loc":{"start":{"line":17,"column":37},"end":{"line":17,"column":51}}}) : helper)))
    + "\" id=\"bb-share-twitter\" class=\"list-group-item\">\n                <div class=\"bb-share-social-button-container\">\n                    <div class=\"btn-social twitter bb-share-social-icon\">\n                        <i class=\"fa fa-twitter\"></i>\n                    </div>\n                    <label class=\"pointer\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"tweet") : stack1), depth0))
    + "</label>\n                </div>\n            </a>\n\n            <a target=\"_blank\" href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"pintrestUrl") || (depth0 != null ? lookupProperty(depth0,"pintrestUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"pintrestUrl","hash":{},"data":data,"loc":{"start":{"line":26,"column":37},"end":{"line":26,"column":52}}}) : helper)))
    + "\" id=\"bb-share-pintrest\" class=\"list-group-item\">\n                <div class=\"bb-share-social-button-container\">\n                    <div class=\"btn-social pintrest bb-share-social-icon\">\n                        <i class=\"fa fa-pinterest-p\"></i>\n                    </div>\n                    <label class=\"pointer\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"pin") : stack1), depth0))
    + "</label>\n                </div>\n            </a>\n\n            <button id=\"bb-share-link\" class=\"list-group-item\">\n                <div class=\"bb-share-social-button-container\">\n                    <div class=\"btn-social link bb-share-social-icon\">\n                        <i class=\"fa fa-link\"></i>\n                    </div>\n                    <label class=\"pointer\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"link") : stack1), depth0))
    + "</label>\n                </div>\n            </button>\n\n        </div>\n\n    </div>\n    <div class=\"bb-share-social-link-copy-result\">\n        <div>\n            <div id=\"bb-share-link-copy-result\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"linkCopyResult") || (depth0 != null ? lookupProperty(depth0,"linkCopyResult") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"linkCopyResult","hash":{},"data":data,"loc":{"start":{"line":59,"column":48},"end":{"line":59,"column":66}}}) : helper)))
    + "</div>\n        </div>\n    </div>\n</div>";
},"useData":true});