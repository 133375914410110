import Handlebars from "handlebars-template-loader/runtime";

Handlebars.registerHelper("itemAt", (array, index, options) => {
  const item = array[index];

  if (item) {
    item.i18n = options.data.root.i18n;
    return options.fn(item);
  } else {
    return options.inverse(this);
  }
});
